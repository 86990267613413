import { Center, HStack, IconButton, Image, Link } from "@chakra-ui/react";
import "./Navigation.css";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import { ETHERSCAN_URL, OPENSEA_COLLECTION_URL } from "./urls";

export default function Footer() {
  return (
    <Center>
      <HStack
        width="80%"
        marginTop="20px"
        marginBottom="20px"
        alignItems="center"
        justifyContent="center"
      >
        <Link isExternal href={ETHERSCAN_URL}>
          <IconButton
            aria-label="Etherscan"
            icon={<EtherscanIcon />}
          ></IconButton>
        </Link>
        <Link isExternal href={OPENSEA_COLLECTION_URL}>
          <IconButton
            style={{
              paddingTop: "4px",
            }}
            aria-label="Opensea"
            icon={<OpenseaIcon />}
          ></IconButton>
        </Link>
        <Link isExternal href="http://discord.miragenesi.art">
          <IconButton
            aria-label="Discord"
            icon={
              <Image
                border="none"
                backgroundColor="white"
                width="34px"
                src="discord.svg"
              />
            }
          ></IconButton>
        </Link>
        <Link isExternal href="https://twitter.com/miragenesi">
          <IconButton
            aria-label="X"
            icon={<Image backgroundColor="white" width="34px" src="x.jpeg" />}
          ></IconButton>
        </Link>
        <Link isExternal href="https://medium.com/@miragenesi">
          <IconButton
            aria-label="Medium"
            icon={
              <Image backgroundColor="white" width="34px" src="medium.svg" />
            }
          ></IconButton>
        </Link>
        <Link isExternal href="https://music.miragenesi.art">
          <IconButton
            aria-label="Bandcamp"
            icon={
              <Image backgroundColor="white" width="34px" src="bandcamp.png" />
            }
          ></IconButton>
        </Link>
      </HStack>
    </Center>
  );
}

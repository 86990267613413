import { ABI_NFT } from "../contract";
import { useContractRead } from "wagmi";
import { useEffect, useState } from "react";

export default function useAdventDay() {
  const [day, setDay] = useState<number>();

  const { data } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "adventDay",
  });

  useEffect(() => {
    if (data) {
      const dayData = data as bigint;
      setDay(Number(dayData));
    }
  }, [data]);

  return day;
}

import {
  Box,
  Center,
  Circle,
  HStack,
  IconButton,
  Image,
  Link,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import "./Calendar.css";
import useNFTSvg from "./hooks/useNFTSvg";
import MintButton from "./MintButton";
import useAdventDay from "./hooks/useAdventDay";
import useSky from "./hooks/useLocalSVG";
import {
  ArrowLeftIcon,
  ArrowLeftIconMobile,
  ArrowRightIcon,
  ArrowRightIconMobile,
} from "./Icons";
import useTokenAtIndex from "./hooks/useTokenAtIndex";
import Minted from "./Minted";
import Explanation from "./Explanation";
import useLocalSVG from "./hooks/useLocalSVG";
import { useAccount } from "wagmi";
import { OPENSEA_NFT_URL } from "./urls";
import ExplanationMobile from "./ExplanationMobile";

const DAYS = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
  "XIII",
  "XIV",
  "XV",
  "XVI",
  "XVII",
  "XVIII",
  "XIX",
  "XX",
  "XXI",
  "XXII",
  "XXIII",
  "XXIV",
];

type CalendarProps = {
  width: number;
  height: number;
};

export default function Calendar(props: CalendarProps) {
  const { address, isConnected } = useAccount();
  const [size, setSize] = useState(window.innerHeight);
  const [fontSize, setFontSize] = useState(1.1);
  const [displayExplanation, setDisplayExplanation] = useState(true);
  const radius = size - 150;
  const innerCircleRadius = size - 40;
  const adventDay = useAdventDay();
  const sky = useLocalSVG("sky.svg");
  const [currentNFTIndex, setCurrentNFTIndex] = useState(0);
  const tokenData = useTokenAtIndex(currentNFTIndex);
  const nextTokenData = useTokenAtIndex(currentNFTIndex + 1);
  const svg = useNFTSvg(tokenData.tokenId);
  const [mobile, setMobile] = useState(false);
  const [shift, setShift] = useState(0);

  const updateSize = (width: number, height: number) => {
    let newSize = Math.min(width * 0.8, height * 0.85);
    if (width < 1024 && height > width) {
      newSize = width * 0.95;
      setMobile(true);
    } else {
      setMobile(false);
    }
    // Calculate the smaller of viewport width or height to maintain aspect ratio
    if (newSize < 500) {
      setShift(30);
    } else {
      setShift(0);
    }
    setSize(newSize);
    setDisplayExplanation(true);
    const area = newSize * newSize;
    if (area >= 740000) {
      setFontSize(1.5);
    } else if (area >= 580000) {
      setFontSize(1.2);
    } else if (area >= 350000) {
      setFontSize(0.9);
    } else if (area >= 280000) {
      setFontSize(0.7);
    } else {
      setDisplayExplanation(false);
    }
  };

  useEffect(() => {
    updateSize(props.width, props.height);
  }, [props.width, props.height]);

  useEffect(() => {
    setCurrentNFTIndex(0);
    tokenData.refetch();
    nextTokenData.refetch();
  }, [address]);

  function refetchAll() {
    tokenData.refetch();
    nextTokenData.refetch();
  }

  function dayClass(i: number) {
    if (adventDay === undefined) {
      return;
    }
    if (adventDay - 1 === i) {
      return "current";
    }
    if (adventDay - 1 < i) {
      return "future";
    }
    if (adventDay - 1 > i) {
      return "past";
    }
  }

  return (
    <Box>
      {mobile && (
        <Box width="100%">
          {tokenData.tokenId !== undefined && (
            <Center>
              <Link
                fontSize="1.2rem"
                href={`${OPENSEA_NFT_URL}${tokenData.tokenId}`}
              >
                View #{tokenData.tokenId.toString()} on Opensea
              </Link>
            </Center>
          )}
          <HStack w="80vw" spacing="20px">
            <Center
              width="100%"
              id="calendar"
              position="relative"
              h={`${size}px`}
              overflow="visible"
            >
              <IconButton
                variant="unstyled"
                borderColor="transparent"
                filter="none"
                icon={<ArrowLeftIconMobile />}
                aria-label={"Left"}
                isDisabled={currentNFTIndex === 0}
                style={{
                  position: "absolute",
                  top: "50%",
                  marginTop: "-10px",
                  marginLeft: `-${radius + 150}px`,
                }}
                onClick={() => setCurrentNFTIndex(currentNFTIndex - 1)}
              />
              {svg && isConnected && (
                <div
                  dangerouslySetInnerHTML={{ __html: svg }}
                  style={{
                    position: "absolute",
                    width: `${radius + shift}px`,
                    height: `${radius + shift}px`,
                    clipPath: "circle(50% at 50% 50%)",
                  }}
                />
              )}
              {(svg === undefined || !isConnected) && (
                <Image
                  src="sky.png"
                  style={{
                    position: "absolute",
                    width: `${radius + shift}px`,
                    height: `${radius + shift}px`,
                    clipPath: "circle(50% at 50% 50%)",
                    opacity: "0.5",
                  }}
                />
              )}
              <Circle
                className="outer"
                position="absolute"
                size={`${innerCircleRadius}px`}
                border="2px solid white"
              />
              <Circle
                position="absolute"
                size={`${innerCircleRadius - (110 - shift)}px`} // 110 beyond 400
                border="1px solid white"
              />
              {(svg === undefined || !isConnected) && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  boxSize={`${radius}px`}
                  clipPath="circle(50% at 50% 50%)"
                >
                  <ExplanationMobile
                    fontSize={shift === 0 ? "1.2rem" : "1rem"}
                  />
                </Box>
              )}
              {Array.from({ length: 24 }).map((_, index) => (
                <Text
                  position="absolute"
                  className={`${dayClass(index)} number`}
                  fontSize="0.7rem"
                  transform={`rotate(${index * 15 + 285}deg) translate(${
                    size / 2 - (50 - shift / 3)
                  }px) rotate(-${index * 15 + 285}deg)`} //50 beyond 400
                  transformOrigin="center"
                  key={index}
                  data-text={DAYS[index]}
                >
                  {DAYS[index]}
                </Text>
              ))}
              <IconButton
                variant="unstyled"
                borderColor="transparent"
                filter="none"
                icon={<ArrowRightIconMobile />}
                aria-label={"Left"}
                isDisabled={nextTokenData.tokenId === undefined}
                style={{
                  position: "absolute",
                  top: "50%",
                  marginTop: "-10px",
                  marginLeft: `${radius + 150}px`,
                }}
                onClick={() => setCurrentNFTIndex(currentNFTIndex + 1)}
              />
            </Center>
          </HStack>
          <Center marginTop="50px">
            <MintButton refetch={refetchAll} />
          </Center>
        </Box>
      )}
      {!mobile && (
        <Box width="100%">
          {tokenData.tokenId !== undefined && (
            <Center>
              <Link
                fontSize="1.2rem"
                href={`${OPENSEA_NFT_URL}${tokenData.tokenId}`}
              >
                View #{tokenData.tokenId.toString()} on Opensea
              </Link>
            </Center>
          )}
          <HStack w="80vw" spacing="20px">
            <Center
              width="100%"
              id="calendar"
              position="relative"
              h={`${size}px`}
              overflow="visible"
            >
              <IconButton
                variant="unstyled"
                borderColor="transparent"
                filter="none"
                icon={<ArrowLeftIcon />}
                aria-label={"Left"}
                isDisabled={currentNFTIndex === 0}
                style={{
                  position: "absolute",
                  top: "50%",
                  marginTop: "-10px",
                  marginLeft: `-${radius + 200}px`,
                }}
                onClick={() => setCurrentNFTIndex(currentNFTIndex - 1)}
              />
              {svg && isConnected && (
                <div
                  dangerouslySetInnerHTML={{ __html: svg }}
                  style={{
                    position: "absolute",
                    width: `${radius}px`,
                    height: `${radius}px`,
                    clipPath: "circle(49% at 50% 50%)",
                  }}
                />
              )}
              {(svg === undefined || !isConnected) && (
                <Box
                  dangerouslySetInnerHTML={{ __html: sky }}
                  style={{
                    position: "absolute",
                    width: `${radius}px`,
                    height: `${radius}px`,
                    clipPath: "circle(49% at 50% 50%)",
                    opacity: "0.4",
                  }}
                />
              )}
              <Circle
                className="outer"
                position="absolute"
                size={`${innerCircleRadius}px`}
                border="2px solid white"
              />
              <Circle
                position="absolute"
                size={`${innerCircleRadius - 125}px`}
                border="1px solid white"
              />

              {(svg === undefined || !isConnected) && (
                <Box
                  alignItems="center"
                  style={{
                    display: "table",
                  }}
                  textAlign="center"
                  boxSize={`${radius}px`}
                  clipPath="circle(50% at 50% 50%)"
                >
                  <VStack
                    padding="50px"
                    alignItems="center"
                    style={{
                      display: "table-cell",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1.4rem",
                      verticalAlign: "middle",
                      textAlign: "center",
                    }}
                  >
                    <Explanation
                      display={displayExplanation ? "inline-block" : "none"}
                      fontSize={`${fontSize}rem`}
                    />

                    <MintButton refetch={refetchAll} />
                  </VStack>
                </Box>
              )}
              {Array.from({ length: 24 }).map((_, index) => (
                <Text
                  position="absolute"
                  className={`${dayClass(index)} number`}
                  transform={`rotate(${index * 15 + 285}deg) translate(${
                    size / 2 - 50
                  }px) rotate(-${index * 15 + 285}deg)`}
                  transformOrigin="center"
                  key={index}
                  data-text={DAYS[index]}
                >
                  {DAYS[index]}
                </Text>
              ))}

              <IconButton
                variant="unstyled"
                filter="none"
                style={{
                  position: "absolute",
                  top: "50%",
                  marginTop: "-10px",
                  marginRight: `-${radius + 200}px`,
                }}
                borderColor="transparent"
                icon={<ArrowRightIcon />}
                aria-label={"Left"}
                isDisabled={nextTokenData.tokenId === undefined}
                onClick={() => setCurrentNFTIndex(currentNFTIndex + 1)}
              />
            </Center>
          </HStack>
          {tokenData.tokenId !== undefined && (
            <Center marginTop="50px">
              <MintButton refetch={refetchAll} />
            </Center>
          )}
        </Box>
      )}
    </Box>
  );
}

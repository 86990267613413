import { Box, Text } from "@chakra-ui/react";
import "./ExplanationMobile.css";
import { useEffect, useState } from "react";

type ExplanationMobileProps = {
  fontSize: string;
};

function ExplanationMobile(props: ExplanationMobileProps) {
  const [visibleSentenceIndex, setVisibleSentenceIndex] = useState(0);

  useEffect(() => {
    console.log("INDEX  ", visibleSentenceIndex);
    if (visibleSentenceIndex < sentences.length - 1) {
      const interval = setInterval(() => {
        setVisibleSentenceIndex(
          (prevIndex) => (prevIndex + 1) % sentences.length
        );
      }, 3500); // Change sentence every 5 seconds
      return () => clearInterval(interval);
    }
  }, [visibleSentenceIndex]);

  const sentences = [
    "A celestial Advent Calendar,<br />to accompany its holder<br/>throughout the advent days",
    "Every day until the 24th,<br/>a new star will shine in the night sky...",
    "... sometimes surrounded<br/>by colorful nebulae",
    "Each NFT will generate<br/>its own unique vibrant firmament.",
    "Only at Christmas Eve<br/>the cosmic artwork will be complete.",
    "Next year? Restart it,<br/>and the magic will happen again.",
    "Buy ↓",
  ];
  const sentencesMobileSmall = [
    "A celestial Advent Calendar,<br />to accompany its holder<br/>throughout the advent days",
    "Every day until the 24th,<br/>a new star will shine<br/>in the night sky...",
    "... sometimes surrounded<br/>by colorful nebulae",
    "Each NFT will generate<br/>its own unique<br/>vibrant firmament.",
    "Only at Christmas Eve<br/>the cosmic artwork</br>will be complete.",
    "Next year? Restart it,<br/>and the magic<br/> will happen again.",
    "Buy ↓",
  ];
  return (
    <Box fontWeight="regular" textShadow="0 0 3px white" textAlign="center">
      <div
        className="sentence"
        style={{
          fontSize: `${props.fontSize}`,
          position: "absolute", // Position each sentence absolutely
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust for exact centering
          width: "100%", // Ensure each sentence div spans the full width of the parent Box
        }}
        dangerouslySetInnerHTML={{
          __html:
            props.fontSize === "1rem"
              ? sentencesMobileSmall[visibleSentenceIndex]
              : sentences[visibleSentenceIndex],
        }}
      />
    </Box>
  );
}

export default ExplanationMobile;

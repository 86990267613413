import {
  Box,
  GridItem,
  Heading,
  IconButton,
  Image,
  Link,
  SimpleGrid,
  ToastId,
  useToast,
} from "@chakra-ui/react";
import "./Navigation.css";
import ConnectButton from "./ConnectButton";
import { EtherscanIcon, OpenseaIcon } from "./Icons";
import Countdown from "./Countdown";
import Minted from "./Minted";
import { useNetwork } from "wagmi";
import { useEffect, useRef } from "react";
import { ETHERSCAN_URL, OPENSEA_COLLECTION_URL } from "./urls";

export default function Navigation() {
  const { chain } = useNetwork();
  const toast = useToast();
  const toastIdRef = useRef<ToastId>();

  useEffect(() => {
    let expectedID = Number(process.env.REACT_APP_CURRENT_NETWORK);
    if (chain?.id !== expectedID && typeof chain !== "undefined") {
      let expectedName = process.env.REACT_APP_CURRENT_NETWORK_NAME;
      toastIdRef.current = toast({
        title: "Wrong Network",
        description: `You are connected to the wrong chain. Connect to ${expectedName}`,
        status: "error",
        duration: 19000,
        isClosable: true,
      });
    } else if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }, [chain, toast]);

  return (
    <Box width="100%">
      <Box
        display={{
          base: "block",
          md: "none",
          lg: "none",
          xl: "none",
          "2xl": "none",
          sm: "block",
        }}
      >
        <SimpleGrid columns={3} marginTop="30px">
          <GridItem colSpan={3} textAlign="center">
            <Heading textShadow="0 0 4px white" as="h1" size="3xl">
              Advent Stars
            </Heading>
          </GridItem>
          <Box
            display="flex"
            textShadow="0 0 4px white"
            alignItems="center"
            justifyContent="center"
          >
            <Minted />
          </Box>
          <Box>
            <ConnectButton />
          </Box>
          <Box
            display="flex"
            textShadow="0 0 4px white"
            alignItems="center"
            justifyContent="center"
          >
            <Countdown />
          </Box>
        </SimpleGrid>
      </Box>
      <Box
        display={{
          base: "none",
          md: "block",
          lg: "block",
          xl: "block",
          "2xl": "block",
          sm: "none",
        }}
      >
        <SimpleGrid columns={6} marginTop="30px">
          <Box>
            <ConnectButton />
          </Box>
          <Box
            display="flex"
            textShadow="0 0 4px white"
            alignItems="center"
            justifyContent="center"
          >
            <Minted />
          </Box>
          <GridItem colSpan={2} textAlign="center">
            <Heading textShadow="0 0 4px white" as="h1" size="4xl">
              Advent Stars
            </Heading>
          </GridItem>
          <Box
            display="flex"
            textShadow="0 0 4px white"
            alignItems="center"
            justifyContent="center"
          >
            <Countdown />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
          >
            <Link isExternal href={ETHERSCAN_URL}>
              <IconButton
                aria-label="Etherscan"
                icon={<EtherscanIcon />}
              ></IconButton>
            </Link>
            <Link isExternal href={OPENSEA_COLLECTION_URL}>
              <IconButton
                style={{
                  paddingTop: "4px",
                  marginLeft: "10px",
                }}
                aria-label="Opensea"
                icon={<OpenseaIcon />}
              ></IconButton>
            </Link>
            <Link isExternal href="http://discord.miragenesi.art">
              <IconButton
                aria-label="Discord"
                style={{
                  marginLeft: "10px",
                }}
                icon={
                  <Image
                    border="none"
                    backgroundColor="white"
                    width="34px"
                    src="discord.svg"
                  />
                }
              ></IconButton>
            </Link>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}

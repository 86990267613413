import "./Carousel.css"; // Import the CSS for styling
import { Box, Image } from "@chakra-ui/react";

export default function Carousel() {
  return (
    <Box boxShadow="0px 0px 5px 0px white" className="carousel">
      {Array.from({ length: 9 }).map((_, index) => (
        <Image
          src={`samples/sample${index + 1}.png`}
          className="carousel-item"
        />
      ))}
    </Box>
  );
}

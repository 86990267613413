import { ABI_NFT } from "../contract";
import { useContractRead } from "wagmi";
import { useEffect, useState } from "react";

export default function useNFTSvg(tokenId: bigint | undefined) {
  const [svg, setSvg] = useState<string>();

  const { data } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "render",
    args: [tokenId],
    enabled: tokenId !== undefined,
  });

  useEffect(() => {
    if (data) {
      const svgData = data as string;
      setSvg(svgData);
    }
  }, [data]);

  useEffect(() => {
    if (tokenId === undefined) {
      setSvg(undefined);
    }
  }, [tokenId]);

  return svg;
}

import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Connector, useAccount, useConnect, useContractRead } from "wagmi";
import { ABI_NFT } from "./contract";
import { useEffect, useState } from "react";
import { Addreth } from "addreth";
import "./ConnectButton.css";
import {
  Button,
  Modal,
  Box,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";

export default function ConnectButton() {
  const { address, isConnected } = useAccount();

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isConnected) {
      onClose();
    }
  }, [isConnected, onClose]);

  function handleConnect(connector: Connector<any, any>) {
    connect({ connector });
    if (connector.id === "walletConnect") {
      onClose();
    }
  }

  return (
    <Box paddingLeft="0px" height="100%">
      <Box id="addreth">
        {!isConnected && <Button onClick={onOpen}>Connect</Button>}
        {isConnected && address && (
          <Addreth
            actions="none"
            label="ens"
            address={address}
            theme="simple-dark"
          />
        )}
      </Box>
      <Modal
        id="connect-modal"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size="sm"
        colorScheme="blackAlpha"
      >
        <ModalOverlay backdropBlur="2px" />
        <ModalContent
          className="connect-modal"
          backgroundColor="rgba(0, 0, 15)"
          border="1px solid white"
        >
          <ModalHeader>Connect Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              {connectors.map((connector) => (
                <Button
                  fontSize="1.2rem"
                  className="connect-button"
                  disabled={!connector.ready}
                  key={connector.id}
                  onClick={() => handleConnect(connector)}
                >
                  {connector.name}
                  {!connector.ready && " (unsupported)"}
                  {isLoading &&
                    connector.id === pendingConnector?.id &&
                    " (connecting)"}
                </Button>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

import {
  Box,
  Center,
  Flex,
  GridItem,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import "./Content.css";
import Carousel from "./Carousel";
import { useEffect, useState } from "react";

type ContentProps = {
  width: number;
  height: number;
};

export default function Content(props: ContentProps) {
  const [mobile, setMobile] = useState(false);

  const updateSize = (width: number, height: number) => {
    if (width < 1024 && height > width) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    updateSize(props.width, props.height);
  }, [props.width, props.height]);

  return (
    <Center marginTop="60px" width="100%" fontSize={mobile ? "1rem" : "1.2rem"}>
      <VStack width={mobile ? "95%" : "90%"}>
        <Heading as="h2" size="3xl">
          The NFT
        </Heading>
        <SimpleGrid
          columns={mobile ? 1 : 3}
          spacing="50px"
          marginTop="40px"
          textAlign={mobile ? "center" : "left"}
        >
          <GridItem colSpan={mobile ? 1 : 2}>
            <Flex direction="column" justifyContent="center" height="100%">
              <Text textShadow="0 0 1px white">
                In 1904, Gerhard Lang's printed the first commercial Advent
                Calendar. 24 boxes, each with a poem to read every day between
                December 1st and 24th. As a child, I also always received an
                Advent Calendar, with sweets, toys, drawings...
                <br />
                <br />
                This tradition instilled in me a sense of wonder and
                expectation, each day presenting a novel surprise, a small yet
                profound joy.
                <br />
                <br />
                Now, in the spirit of this age-old tradition, this NFT endeavors
                to capture that very essence, offering its holders a similar
                journey of anticipation and daily discovery, guiding them
                through the advent days with a sense of wonder.
                <br />
              </Text>
            </Flex>
          </GridItem>
          <GridItem>
            <Flex direction="column" justifyContent="center" height="100%">
              <Box className="responsive-iframe" width="100%">
                <Image src="sample_5.png" />
              </Box>
            </Flex>
          </GridItem>
          <GridItem>
            <Flex direction="column" justifyContent="center" height="100%">
              <Box className="responsive-iframe" width="100%">
                <Image src="sample_13.png" />
              </Box>
            </Flex>
          </GridItem>
          <GridItem colSpan={mobile ? 1 : 2}>
            <Flex direction="column" justifyContent="center" height="100%">
              <Text
                textShadow="0 0 1px white"
                textAlign={mobile ? "center" : "left"}
              >
                Every day, a new star will illuminate the sky, an opportunity to
                make a wish, for yourself, your loved ones, and the world.
                <br />
                <br />
                Each token will create a distinctive and evolving composition of
                twinkling stars and colorful nebulae.
                <br />
                <br />
                The NFT's appearance will change daily until the 24th. What
                appears as a cloud today may transform into tomorrow's star.
                <br />
                <br />
                On Christmas Eve, the celestial display will be fully realized,
                thus completing the cosmic artwork.
              </Text>
            </Flex>
          </GridItem>
          {mobile && (
            <GridItem>
              <Flex direction="column" justifyContent="center" height="100%">
                <Box className="responsive-iframe" width="100%">
                  <Image src="sample_24.png" />
                </Box>
              </Flex>
            </GridItem>
          )}
          <GridItem colSpan={mobile ? 1 : 2}>
            <Flex direction="column" justifyContent="center" height="100%">
              <Text
                textShadow="0 0 1px white"
                textAlign={mobile ? "center" : "left"}
              >
                The enchantment continues: each year, in the days leading up to
                the Advent, the token holder has the opportunity to restart
                their calendar.
                <br />
                <br />
                The sky will darken again and the magic will start over, ready
                to unfold a new chapter of magic, birthing unseen constellations
                and nebulae, slowly coming to life over the course of the month.
                <br />
                <br />
                An artwork designed to offer moments of contemplation as we
                gently embrace the spirit of the Christmas season.
                <br />
                <br />
                100% on-chain. A symbolic supply of 432 tokens has been set. If
                less are minted before the end, the supply will be shrinked.
              </Text>
            </Flex>
          </GridItem>
          {!mobile && (
            <GridItem>
              <Flex direction="column" justifyContent="center" height="100%">
                <Box className="responsive-iframe" width="100%">
                  <Image src="sample_24.png" />
                </Box>
              </Flex>
            </GridItem>
          )}
          {mobile && <Carousel />}
          {!mobile && (
            <GridItem colSpan={3}>
              {" "}
              <Carousel />
            </GridItem>
          )}
        </SimpleGrid>

        <Heading as="h2" marginTop="30px" marginBottom="20px" size="4xl">
          About Me
        </Heading>
        <Text textShadow="0 0 1px white" textAlign="center">
          I am Miragenesi.
          <br />
          <br />I create on-chain NFT art since 2021. My pioneering work,{" "}
          <Link href="https://opensea.io/collection/moon-in-motion">
            The Moon in Motion
          </Link>
          ,<br />
          stands as an early example of dynamic on-chain NFT art.
          <br />
          <br />
          Prior to this, I crafted my first off-chain collection,
          <br />
          <Link href="https://opensea.io/collection/astrumnft">
            Astrum - Messier Catalog
          </Link>{" "}
          to which "Advent Stars" pays homage.
          <br />
          <br />
          Prior to that, I was (I still am) making music, delving into the
          realms of synth, ambient, and space music, along with occasional piano
          compositions. Check out{" "}
          <Link href="https://soundcloud.com/miragenesi">
            Soundcloud
          </Link> and <Link href="https://music.miragenesi.art">Bandcamp</Link>
          <br />
          <br />
          Prior to all this, I was a little child.
          <br />
          <br />
          Follow me on <Link href="https://twitter.com/miragenesi">X</Link> or
          join my <Link href="http://discord.miragenesi.art">Discord</Link> for
          a more natural and direct conversation.
        </Text>
      </VStack>
    </Center>
  );
}

import "./App.css";
import { Box, Center, VStack } from "@chakra-ui/react";
import Content from "./Content";
import Navigation from "./Navigation";
import Footer from "./Footer";
import { BrowserRouter } from "react-router-dom";
import Calendar from "./Calendar";
import { useEffect, useState } from "react";

export default function Main() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateSize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <BrowserRouter>
      <Navigation />

      <Center>
        <VStack maxWidth="1880px" padding="30px">
          <Calendar width={width} height={height} />
          <Content width={width} height={height} />
          {/*<Box
            width="100%"
            display={{
              base: "none",
              md: "block",
              lg: "block",
              xl: "block",
              "2xl": "block",
              sm: "none",
            }}
          >
          </Box>*/}
          {/*<Box
            display={{
              base: "block",
              md: "none",
              lg: "none",
              xl: "none",
              "2xl": "none",
              sm: "block",
            }}
          >
            <ContentMobile />
          </Box>*/}
        </VStack>
      </Center>
      <Footer />
    </BrowserRouter>
  );
}

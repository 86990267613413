import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCountdown } from "./hooks/useCountdown";

function Countdown() {
  const end = 1701392461;
  const [timeLeft, setTimeLeft] = useState(0);
  const [hours, minutes, seconds] = useCountdown(timeLeft);

  useEffect(() => {
    if (end > Date.now() / 1000) setTimeLeft(end - Date.now() / 1000);
    else {
      setTimeLeft(0);
    }
  }, []);

  function sentence1() {
    if (timeLeft > 0) {
      return `Sales end in`;
    } else {
      return `Sales over.`;
    }
  }

  function sentence2() {
    if (timeLeft > 0) {
      return `${hours}:${minutes}:${seconds}`;
    } else {
      return ``;
    }
  }

  return (
    <div className="Countdown">
      <Text textAlign="center" fontSize={{ base: "0.9rem", md: "1.2rem" }}>
        <div>
          {sentence1()}
          <br />
          {sentence2()}
        </div>
      </Text>
    </div>
  );
}

export default Countdown;

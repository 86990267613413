import { Button, Center, Flex, Link } from "@chakra-ui/react";
import { OPENSEA_COLLECTION_URL } from "./urls";
import { OpenseaIcon } from "./Icons";

type MintButtonProps = {
  refetch: () => void;
};

export default function MintButton(props: MintButtonProps) {
  return (
    <Center marginTop={{ base: "none", md: "20px" }}>
      <Flex direction="row">
        <Button rightIcon={<OpenseaIcon />} paddingTop="4px">
          <Link
            style={{
              textDecoration: "none",
            }}
            href={OPENSEA_COLLECTION_URL}
          >
            Buy on the Market
          </Link>
        </Button>
      </Flex>
    </Center>
  );
}

import { ABI_NFT } from "../contract";
import { useContractRead } from "wagmi";
import { useEffect, useState } from "react";

export default function useMinted() {
  const [minted, setMinted] = useState<bigint>();

  const { data } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "minted",
    watch: true,
  });

  useEffect(() => {
    if (data) {
      const mintedData = data as bigint;
      setMinted(mintedData);
    }
  }, [data]);

  return minted;
}

import { Box, Text } from "@chakra-ui/react";

type ExplanationProps = {
  fontSize: any;
  display: any;
};

function Explanation(props: ExplanationProps) {
  return (
    <Box
      display={props.display}
      fontSize={props.fontSize}
      fontWeight="regular"
      textShadow="0 0 3px white"
      textAlign="center"
    >
      An on-chain Advent Calendar,
      <br /> to accompany its holder throughout the advent days.
      <br />
      <br />
      Every day until the 24th, a new star will illuminate the night sky,
      occasionally surrounded by colorful nebulae.
      <br />
      <br />
      Each NFT will generate its own unique vibrant firmament.
      <br />
      On Christmas Eve, the celestial display will be fully realized, thus
      completing the cosmic artwork.
      <br />
      <br />
      Next year? Restart it, and the magic will happen again.
    </Box>
  );
}

export default Explanation;

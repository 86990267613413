import { ABI_NFT } from "../contract";
import { useAccount, useContractRead } from "wagmi";
import { useEffect, useState } from "react";

export declare type TokenData = {
  tokenId: bigint;
  refetch: () => void;
};

export default function useTokenAtIndex(index: number) {
  const { isConnected, address } = useAccount();
  const [tokenId, setTokenId] = useState<bigint>();
  const { data, isError, error, refetch } = useContractRead({
    address: `0x${process.env.REACT_APP_CONTRACT_ADDRESS}`,
    abi: ABI_NFT,
    functionName: "tokenAtIndex",
    args: [index],
    account: isConnected
      ? address
      : "0x0000000000000000000000000000000000000000",
  });

  useEffect(() => {
    if (data && !isError) {
      setTokenId(data as bigint);
    } else {
      setTokenId(undefined);
    }
  }, [data, isError]);

  return { tokenId, refetch } as TokenData;
}

import { Text } from "@chakra-ui/react";
import useMinted from "./hooks/useMinted";

function Minted() {
  const minted = useMinted();

  return (
    <Text textAlign="center" fontSize={{ base: "0.9rem", md: "1.2rem" }}>
      Minted
      <br />
      {minted === undefined
        ? "..."
        : minted >= 300
        ? `${minted.toString()}/432`
        : minted.toString()}
    </Text>
  );
}

export default Minted;

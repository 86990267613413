import "./App.css";
import { sepolia, mainnet, localhost } from "wagmi/chains";
import { extendTheme } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { WagmiConfig, createConfig, configureChains } from "wagmi";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { InjectedConnector } from "wagmi/connectors/injected";
import Main from "./Main";

const theme = extendTheme({
  fonts: {
    heading: `"MoonHeading", "Arial", "serif"`,
    body: `"Zodiac", "Arial", "serif"`,
  },
  styles: {
    global: {
      // styles for the `body`
      "html, body": {
        bg: "rgba(0,0,15)",
        color: "hsl(25,0%,90%)",
      },
    },
  },
  breakpoints: {
    sm: "30em",
    md: "80em",
  },

  components: {
    Link: {
      baseStyle: {
        textDecoration: "underline", // Ensure links are always underlined
      },
    },
    Button: {
      baseStyle: {
        bgColor: "black",
        borderColor: "hsl(25,0%,90%)",
        borderWidth: "1px",
        borderRadius: 3,
        color: "hsl(25,0%,90%)",
        filter: "drop-shadow(0 0 0.3rem hsl(25,0%,90%,0.5))",
        _hover: {
          //bgColor: "var(--chakra-colors-whiteAlpha-500)",
          borderBottomColor: "black",
          borderLeftColor: "black",
          borderTopColor: "black",
          borderRightColor: "black",
          filter: "drop-shadow(0 0 1rem hsl(25,0%,90%,0.5))",
        },
      },
      variants: {
        // Make a variant, we'll call it `base` here and leave it empty
        base: {},
        secondary: {
          //...define other variants
        },
      },
      defaultProps: {
        // Then here we set the base variant as the default
        variant: "base",
      },
    },
  },
});

// 1. Get projectId
const network =
  process.env.REACT_APP_CURRENT_NETWORK! === "1" ? mainnet : sepolia;
const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!;

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [network],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY! }),
    publicProvider(),
  ]
);

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

export default function App() {
  return (
    <WagmiConfig config={config}>
      <ChakraProvider theme={theme}>
        <Main />
      </ChakraProvider>
    </WagmiConfig>
  );
}
